import PouchDB from 'pouchdb-browser';
import { COUCH_URL, COUCH_DATABASE } from "./constants";

export const db = new PouchDB('reading_lists');
export const remoteDatabase = new PouchDB(`${COUCH_URL}/${COUCH_DATABASE}`);

PouchDB.sync(db, remoteDatabase, {
    live: true,
    heartbeat: false,
    timeout: false,
    retry: true
});