import React, { useState, useEffect } from 'react';
import * as Actions from '../../store/actions';
import { useSnackbar } from 'notistack';


import { OCR_ENGINE } from "../../constants";

import { API_OCR } from '../../services/OCRService';

import { Formik } from "formik";

import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { updateStatusIsLoading } from '../../store/actions';

import { Form } from "./form";
import Paper from "@material-ui/core/Paper";
import Snackbar from '@material-ui/core/Snackbar';
import Fade from '@material-ui/core/Fade';


import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';


import WebcamCapture from '../WebcamCapture';

import * as Yup from "yup";
import { db, remoteDatabase } from '../../api';

const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    //address: Yup.string().required("Address is required"),
    rgpd_check: Yup.bool().oneOf([true], 'Field must be checked'),
    email: Yup.string("Enter your email").email("Enter a valid email").required("Email is required"),
    //gender: Yup.string("Pick a gender").required("Gender is required"), */
    //country: Yup.string("Pick your Country").required("Country is required"),* /
});

const useStyles = makeStyles(theme => ({

    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme
            .spacing(5)}px`
    },

    paperCameraButtons: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(3)}px`,
        marginBottom: theme.spacing(2),
        width: '100%',
    },

    picAvatar: {
        margin: theme.spacing(1),
    },

    picAttachment: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },

    chip: {
        margin: theme.spacing(1),
    },

    submit: {

        //background: 'linear-gradient(45deg, #1289ac 30%, #c97f07 90%)',
        border: 0,
        borderRadius: '3px',
        color: 'white',
        height: 48,
        padding: '0 30px'
    },

    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    card: {
        maxWidth: 345,
        marginTop: '50px'
    },
    formControl: {
        margin: theme.spacing(3),
    },

    button: {
        margin: theme.spacing(1),
    },

}));

function InputForm(props) {

    const initVals = {
        name: "",
        email: "",
        phone: "",
        country: "",
        company: "",
        address: "",
        notes: "",
        pickedRequests: [],
        pickedProducts: [],
        rgpd_check: false,
        request_newsletters_check: false
    }

    const classes = useStyles();
    const dispatch = useDispatch();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [pic, setPic] = useState(null);
    const [attachment, setAttachment] = useState(null);

    //const [cardPic, setCardPic] = useState(null);
    const [ocrResultArr, setOcrResultArr] = useState(null);

    const [openFrontCamera, setOpenFrontCamera] = useState(false);
    const [openBackCamera, setOpenBackCamera] = useState(false);
    //const [currentFormData, setCurrentFormData] = useState({});
    const [initialFormData, setInitialFormData] = useState(initVals);

    const defaultCheckboxes = {
        requests: [
            "Tables", "Tech. Info", "Catalogs", "Product Samples"
        ],
        products: ["Sagifloor", "Sagiwall", "Sagirev", "Sagideck"],
    }

    const appNetworkStatus = useSelector(state => state.app_status_online);

    const handleOpenFront = (type) => {
        setOpenFrontCamera(true);
    };

    const handleCloseFront = () => {
        setOpenFrontCamera(false);
    };

    const handleOpenBack = (type) => {
        setOpenBackCamera(true);
    };
    const handleCloseBack = () => {
        setOpenBackCamera(false);
    };

    const handlePicTaken = (pic, mode) => {
        if (mode == "front")
            setPic(pic);
        //else
        //  setCardPic(pic)
    }

    const resetValues = () => {
        setPic(null);
        setAttachment(null);
        //setCardPic(null);
        setOcrResultArr(null)
        setInitialFormData(initVals);
    }

    /*
    const handleFormChange = (data) => {
        setCurrentFormData(data);
    }*/

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const type = e.target.name;

        var reader = new FileReader();
        reader.onloadend = function () {
            if (file.type == "image/jpeg" || file.type == "image/png") {
                handlePicTaken(reader.result, type);
                //setCardPic(reader.result);
                if (type == "card")
                    handleOCR(reader.result);
                else if (type == "attachment")
                    setAttachment(reader.result);
                else
                    setPic(reader.result);
            }
            else
                enqueueSnackbar("ONLY PHOTOS PLEASE (JPG or PNG), 1MB MAX. SIZE", { variant: 'error' });
        }
        reader.readAsDataURL(file);
    }

    const handleChipClick = (element) => {
        if (/([(+]*[0-9]+[()+. -]*)/.test(element.replace(/\s/g, ''))) {
            //document.execCommand('copy');
            if (window.confirm("PHONE")) setInitialFormData({ ...initialFormData, phone: element });
        }
        if (/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/.test(element)) {
            if (window.confirm("EMAIL?")) setInitialFormData({ ...initialFormData, email: element });
        }
        if (/^[a-zA-Z ]+$/.test(element)) {
            if (window.confirm("NAME?")) setInitialFormData({ ...initialFormData, name: element });
        }
    }

    const handleOCR = (cardPic) => {

        if (cardPic) {

            dispatch(Actions.updateStatusIsLoading(true));

            var bodyFormData = new FormData();
            bodyFormData.set('OCREngine', OCR_ENGINE);
            bodyFormData.set('base64Image', cardPic);
            bodyFormData.set('isTable', 'true');

            API_OCR.post(bodyFormData).then((resolvedValue) => {
                if (resolvedValue.data.IsErroredOnProcessing) {
                    enqueueSnackbar(resolvedValue.data.ErrorMessage, { variant: 'error' });
                } else {
                    const lines = resolvedValue.data.ParsedResults[0].TextOverlay.Lines.map(line => line.LineText + " ");
                    setOcrResultArr(resolvedValue.data.ParsedResults[0].TextOverlay.Lines);
                    enqueueSnackbar(`OCR SUCCESSFULLY RUN IN ${resolvedValue.data.ProcessingTimeInMilliseconds} ms AND DETECTED ${resolvedValue.data.ParsedResults[0].TextOverlay.Lines.length} ITEMS`, { variant: 'success' });
                };
                dispatch(Actions.updateStatusIsLoading(false));
            })
        }
    }

    const submitValues = (submitedData, actions) => {

        dispatch(updateStatusIsLoading(true));

        const obj = {
            _id: new Date().toJSON(),
            name: submitedData.email,
            userData: submitedData,
            _attachments: {
                pic: null,
                attachment: null
            }
        }

        if (pic) {
            obj._attachments.pic = {
                content_type: 'image/jpeg',
                data: pic.split(',')[1]
            }
        }

        if (attachment) {
            obj._attachments.attachment = {
                content_type: 'image/jpeg',
                data: attachment.split(',')[1]
            }
        }

        db.put(obj).then(function () {

            dispatch(updateStatusIsLoading(false));
            props.refresh(this);

            enqueueSnackbar("SUBMITED!", { variant: 'success' });

            actions.resetForm();
            //setPic(null);
            //setCardPic(null);

            resetValues();

        })
    }

    return (
        <React.Fragment>


            <div className={classes.container}>

                <Paper elevation={1} className={classes.paper}>

                    <Formik
                        render={props =>
                            <Form
                                {...props}
                                classes={classes}

                                requests={defaultCheckboxes.requests}
                                products={defaultCheckboxes.products}

                                handleFileChange={handleFileChange}
                                //handleOCR={handleOCR}
                                handleChipClick={handleChipClick}

                                //hasCardPic={cardPic ? true : false}
                                appNetworkStatus={appNetworkStatus}

                                //handleFormChanges={handleFormChange} 

                                ocrResultArr={ocrResultArr}
                                attachmentTaken={attachment}
                                picTaken={pic}
                            />
                        }

                        enableReinitialize={true}
                        initialValues={initialFormData}
                        validationSchema={validationSchema}
                        onSubmit={submitValues}
                        onReset={resetValues}
                    />


                </Paper>
            </div>
        </React.Fragment>
    );

}

function FinalCard(props) {
    const classes = useStyles();

    return (
        <Card className={classes.card}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    image={props.pic}
                    title="User PIC"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {props.userData.name}
                    </Typography>

                    <Typography variant="body2" color="textSecondary" component="p">
                        {props.userData.email}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>

            </CardActions>
        </Card>
    )
}
export default InputForm