import axios from 'axios';
import { OCR_TOKEN, OCR_ENDPOINT } from "../constants";

const API = axios.create({
    baseURL: OCR_ENDPOINT,
    headers: {
        'Content-Type': "multipart/form-data",
        'apikey': OCR_TOKEN
    }
});

export class API_OCR {
    static post = (payload) => new Promise((resolve, reject) => {
        API.post("image", payload).then(function (results) {
            resolve(results);
        }).catch(function (error) {
            reject(new Error(error));
        });
    });
}