import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import * as Actions from './store/actions';
import { SnackbarProvider } from 'notistack';

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { makeStyles } from '@material-ui/core/styles';

import lightTheme from './themeLight';
import darkTheme from './themeDark';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

import SignalWifiOffIcon from '@material-ui/icons/SignalWifiOff';
import WifiIcon from '@material-ui/icons/Wifi';
import WarningIcon from '@material-ui/icons/Warning';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import ContactsIcon from '@material-ui/icons/Contacts';

//import Welcome from "./components/Welcome";
import Front from "./components/Front";
import Back from "./components/Back";

import { Detector } from "react-detect-offline";

import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';


//import Link from '@material-ui/core/Link';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import PicLogo from './assets/sagiper-logo.svg'
import BTSLogo from './assets/bts-logo.png'

import Badge from '@material-ui/core/Badge';
import StorageIcon from '@material-ui/icons/Storage';
import WbIncandescentIcon from '@material-ui/icons/WbIncandescent';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';

import Drawer from '@material-ui/core/Drawer'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import sagiperVideoScreensaver from "./assets/sagiper-screensaver.mp4";

import ReactPlayer from 'react-player/lib/players/FilePlayer'
import IdleMonitor from 'react-simple-idle-monitor';

import "./App.css";

import Loader from './components/Loader';

import { db } from './api';
import { COUCH_URL, IDLE_TIME, HEARTBEAT_DB_TIME } from "./constants";

import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({

  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  drawerRoot: {
    width: '250px'
  },

  topBar: {
    background: 'linear-gradient(45deg, #1289ac 30%, #c97f07 90%)',
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },

  videoContainer: {
    //background: theme.palette.background.default,
    backgroundColor: theme.palette.background.paper,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 9999999999
  },

  videoInner: {
    minWidth: '100%',
    minHeight: '100%',
    width: 'auto',
    height: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(1.3)',
  },

  title: {
    flexGrow: 1,
  },

  container: {
    display: 'flex',
  },
  paper: {
    margin: theme.spacing(1),
    paddingBottom: 50,
  },
  margin: {
    margin: theme.spacing(2),
  },
  padding: {
    padding: theme.spacing(0, 2),
  },
  logoPic: {
    maxWidth: '110px'
  },

  footer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(6)

  },

  logoFooter: {
    maxWidth: '10px'
  },

  text: {
    padding: theme.spacing(2, 2, 0),
  },

}));


function App() {

  const dispatch = useDispatch();

  const totalRecords = useSelector(state => state.total_db_records);
  const appDBStatus = useSelector(state => state.app_status_remoteDB);
  const appNetworkStatus = useSelector(state => state.app_status_online);
  const appLoadingStatus = useSelector(state => state.app_status_isLoading);

  const { screenSaver } = useSelector(state => state.app_settings);
  const { darkMode } = useSelector(state => state.app_settings);

  const testNetworkStatus = new Detector();
  const [mytheme, setMyTheme] = useState({
    palette: {
      type: darkMode ? "dark" : "light",
    }
  });

  const [online, setOnline] = useState(false);
  const [drawer, setdrawer] = useState(false);
  const [idle, setIdle] = useState(false);

  const onActive = () => {
    setIdle(false);
  }

  const onIdle = () => {
    setIdle(true);
  }

  const handleDarkModelToggle = (e) => {
    let newPaletteType = mytheme.palette.type === "light" ? "dark" : "light";

    setMyTheme({
      palette: {
        type: newPaletteType
      }
    });
    dispatch(Actions.updateSettingsDarkmode(!darkMode));
  }

  const handleScreensaverToggle = (e) => {
    dispatch(Actions.updateSettingsScreensaver(!screenSaver));
  }

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setdrawer(open);
  };

  window.addEventListener("online", () => {
    dispatch(Actions.updateStatusOnline(true));
  });

  window.addEventListener("offline", () => {
    dispatch(Actions.updateStatusOnline(false));
  });

  useEffect(() => {
    dispatch(Actions.updateStatusRemoteDb(online));
  }, [online]);

  useEffect(() => {
    dispatch(Actions.updateStatusOnline(testNetworkStatus.state.online));
    heartBeat();
    fetchData();

    const liveDBUpdater = db.changes({
      since: 'now',
      live: true,
      include_docs: true,
    }).on('change', () => {
      fetchData();
    });

  }, ['']);

  const themeLight = createMuiTheme(lightTheme);
  const themeDark = createMuiTheme(darkTheme)

  const classes = useStyles();

  const heartBeat = () => {

    const fetchInit = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      cache: 'default',
    };


    const fetchRequest = new Request(`${COUCH_URL}/_up`, fetchInit);

    fetch(fetchRequest).then((result) => {
      if (result.ok && !online) {
        setOnline(true);
      } else if (!result.ok) {
        setOnline(false);
      }
      setTimeout(heartBeat.bind(this), HEARTBEAT_DB_TIME);
    }).catch(() => {
      setOnline(false);
      setTimeout(heartBeat.bind(this), HEARTBEAT_DB_TIME);
    });
  };

  const fetchData = () => {
    db.allDocs({
      include_docs: false,
      attachments: false,
    }).then(result => {
      const rows = result.rows;
      dispatch(Actions.updateTotalRecordsAction(rows.length));
      //dispatch(Actions.updateStatusIsLoading(false));
    }).catch((err) => {
      console.log(err);
      //dispatch(Actions.updateStatusIsLoading(false));
    });
  }

  return (

    <MuiThemeProvider theme={darkMode ? createMuiTheme(themeDark) : createMuiTheme(themeLight)}>
      <SnackbarProvider hideIconVariant={false}>
        <IdleMonitor
          activeClassName="user-is-working"
          idleClassName="UI-is-idle"
          onActive={onActive}
          onIdle={onIdle}
          timeout={IDLE_TIME}
        />

        {
          appLoadingStatus && (
            <Loader />
          )
        }

        {
          idle && screenSaver && (
            <div className={classes.videoContainer}>
              <ReactPlayer
                url={sagiperVideoScreensaver}
                className={classes.videoInner}
                playing
                loop
                muted />)
          </div>
          )
        }

        <div className={classes.root}>

          <AppBar
            position="static"
            className={classes.topBar}
          >

            <Toolbar>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                onClick={toggleDrawer('left', !drawer)}
                aria-label="menu">
                <MenuIcon />
              </IconButton>

              <Typography variant="h6" className={classes.title}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                >
                  <img
                    className={classes.logoPic}
                    src={PicLogo}
                    alt="SAGIPER APP"
                  />
                </Grid>
              </Typography>

            </Toolbar>
          </AppBar>

          <Router>

            <Container component="main" className={classes.main} maxWidth="md">
              <Route path="/" exact render={(props) => <Front {...props} fetchData={fetchData} />} />
              <Route path="/backoffice" exact component={Back} />
            </Container>

            <Drawer
              open={drawer}
              onClose={toggleDrawer('left', false)}
            >

              <div
                className={classes.drawerRoot}
                role="presentation"
                onClick={toggleDrawer('left', false)}
                onKeyDown={toggleDrawer('left', false)}
              >

                <CssBaseline />
                <List>

                  {/*
                <ListItem
                  button
                  {...{ to: '/' }}
                  component={Link}
                >
                  <ListItemIcon><HomeIcon /></ListItemIcon>
                  <ListItemText primary={"Home"} />
</ListItem>*/}

                  <ListItem
                    button
                    {...{ to: '/' }}
                    component={Link}
                  >
                    <ListItemIcon><ContactsIcon /></ListItemIcon>
                    <ListItemText primary={"Form"} />
                  </ListItem>

                  <ListItem
                    button
                    {...{ to: '/backoffice' }}
                    component={Link}
                  >
                    <ListItemIcon><StorageIcon /></ListItemIcon>
                    <ListItemText
                      disableTypography
                      primary={<Typography type="body2">Management</Typography>}
                    />
                  </ListItem>


                </List>

                <Divider />

                <List subheader={<ListSubheader><em>STATUS</em></ListSubheader>}>

                  {appNetworkStatus && (
                    <ListItem>
                      <ListItemText id="switch-list-label-wifi" primary="API" />
                      <ListItemSecondaryAction>

                        {appDBStatus && (
                          <Badge className={classes.margin} badgeContent={totalRecords} color="secondary">
                            <StorageIcon />
                          </Badge>
                        )}

                        {!appDBStatus && (

                          <WarningIcon />

                        )}

                      </ListItemSecondaryAction>
                    </ListItem>
                  )}

                  {appNetworkStatus && (
                    <ListItem>
                      <ListItemText
                        id="switch-list-label-wifi"
                        primary="Online"
                      />
                      <ListItemSecondaryAction>
                        <WifiIcon className={classes.margin} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}

                  {!appNetworkStatus && (
                    <ListItem>
                      <ListItemText id="switch-list-label-wifi" primary="Offline" />
                      <ListItemSecondaryAction>
                        <SignalWifiOffIcon className={classes.margin} />
                      </ListItemSecondaryAction>
                    </ListItem>
                  )}

                </List>

                <Divider />

                <List subheader={<ListSubheader><em>SETTINGS</em></ListSubheader>}>

                  <ListItem button >

                    <FormControlLabel
                      control={
                        <Switch color="primary" checked={darkMode} onChange={handleDarkModelToggle} value={true} />
                      }
                      label="Night Mode"
                    />

                  </ListItem>

                  <ListItem button >
                    <FormControlLabel
                      control={
                        <Switch color="primary" checked={screenSaver} onChange={handleScreensaverToggle} value={true} />
                      }
                      label="Screensaver"
                    />
                  </ListItem>

                </List>



              </div>
            </Drawer>
          </Router>

          <footer className={classes.footer}>
            <Container maxWidth="sm">
              <Copyright classes={classes} />
            </Container>
          </footer>

        </div>


      </SnackbarProvider>
    </MuiThemeProvider >
  )
}

const Copyright = (props) => {
  return (
    <>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        {'SAGIPER'}
        {new Date().getFullYear() + " "}
        | by Brandtellers Studio
      </Typography>
    </>
  );
}

export default App;
