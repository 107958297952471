import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import VisibilityIcon from '@material-ui/icons/Visibility';
import Avatar from '@material-ui/core/Avatar';
import CachedIcon from '@material-ui/icons/Cached';
import CameraRollIcon from '@material-ui/icons/CameraRoll';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Paper from "@material-ui/core/Paper";
import AttachFileIcon from '@material-ui/icons/AttachFile';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import RecentActorsIcon from '@material-ui/icons/RecentActors';

import { Autocomplete } from "material-ui-formik-components/Autocomplete";
import countries from "./countries.json";

import { Field, FieldArray } from "formik";
import { Select } from "material-ui-formik-components/Select";

import EmailIcon from "@material-ui/icons/Email";
import SendIcon from '@material-ui/icons/Send';
import CameraIcon from '@material-ui/icons/Camera';
import Grid from '@material-ui/core/Grid';

export const Form = props => {
    const {

        values: {
            name,
            email,
            phone,
            company,
            address,
            notes,

            country,
            rgpd_check,
            request_newsletters_check,

            pickedRequests,
            pickedProducts
        },

        errors,
        touched,
        classes,
        picTaken,
        attachmentTaken,
        //hasCardPic,
        appNetworkStatus,

        requests,
        products,

        handleSubmit,
        handleReset,
        handleChange,
        //handleFormChanges,
        isValid,



        //handleOCR,

        ocrResultArr,
        handleChipClick,


        handleFileChange,
        setFieldTouched
    } = props;

    //console.log(props.values);

    const change = (name, e) => {
        e.persist();
        handleChange(e);
        //handleFormChanges(props.values);
        setFieldTouched(name, true, false);
    };

    return (
        <React.Fragment>

            <Paper className={classes.paperCameraButtons}>

                <Grid container>

                    {picTaken && (
                        <Grid item >
                            <Avatar className={classes.picAvatar} alt="Profile Picture" src={picTaken} />
                        </Grid>
                    )}

                    <Grid item>
                        <Button style={{ position: 'relative' }} className={classes.button} variant="outlined" color="default">
                            Snapshot
                            <CameraIcon className={classes.rightIcon} />
                            <input name="pic" style={{ position: 'absolute', opacity: 0, width: '100%', height: '100%' }} type="file" onChange={handleFileChange} />
                        </Button>

                        <Button disabled={!appNetworkStatus} style={{ position: 'relative' }} className={classes.button} variant="outlined" color="default">
                            OCR
                            <VisibilityIcon className={classes.rightIcon} />
                            <input name="card" style={{ position: 'absolute', opacity: 0, width: '100%', height: '100%' }} type="file" onChange={handleFileChange} />
                        </Button>
                    </Grid>
                </Grid>

                <Grid container>
                    {ocrResultArr && (
                        <Grid item xs={12}>
                            {ocrResultArr.map(element => <Chip key={element.LineText} onClick={() => handleChipClick(element.LineText)} label={element.LineText} className={classes.chip} />)}
                        </Grid>
                    )}

                </Grid>
            </Paper>

            <form onSubmit={handleSubmit} noValidate>

                <Grid container spacing={3}>

                    <Grid item xs={12} sm={6}>
                        <Field
                            id="name"
                            name="name"
                            required
                            component={TextField}
                            helperText={touched.name ? errors.name : ""}
                            error={touched.name && Boolean(errors.name)}
                            label="Name"
                            value={name}
                            onChange={change.bind(null, "name")}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Field
                            id="company"
                            name="company"
                            value={company}
                            component={TextField}
                            helperText={touched.company ? errors.company : ""}
                            error={touched.company && Boolean(errors.company)}
                            label="Company"
                            onChange={change.bind(null, "company")}
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Field
                            id="address"
                            name="address"
                            value={address}

                            component={TextField}
                            helperText={touched.address ? errors.address : ""}
                            error={touched.company && Boolean(errors.address)}
                            label="Address"
                            onChange={change.bind(null, "address")}
                            fullWidth
                        />
                    </Grid>


                    <Grid item xs={12} sm={4}>
                        <Field
                            name="email"
                            id="email"
                            label="E-mail"
                            helperText={touched.email ? errors.email : ""}
                            error={touched.email && Boolean(errors.email)}
                            fullWidth
                            value={email}
                            onChange={change.bind(null, "email")}
                            type="email"
                            required
                            component={TextField}
                        />

                        <Field
                            name="phone"
                            id="phone"
                            label="Phone"
                            helperText={touched.phone ? errors.phone : ""}
                            error={touched.phone && Boolean(errors.phone)}
                            fullWidth
                            value={phone}
                            onChange={change.bind(null, "phone")}
                            type="tel"
                            component={TextField}
                        />

                        {/*}
                        <Field
                            name="gender"
                            fullWidth
                            label="Gender"
                            onChange={change.bind(null, "gender")}
                            options={[
                                { value: "Male", label: "Male" },
                                { value: "Female", label: "Female" },
                                { value: "Other", label: "Other" }
                            ]}
                            component={Select}
                        />*/}

                        <Field
                            fullWidth
                            name="country"
                            label="Country"
                            onChange={change.bind(null, "country")}
                            options={countries}
                            component={Select}
                        />

                    </Grid>

                    <Grid item xs={12} sm={8}>
                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Product</FormLabel>
                            <FormGroup>
                                <FieldArray
                                    name="pickedProducts"
                                    render={arrayHelpers => (
                                        <>
                                            {products.map(category => (
                                                <FormControlLabel
                                                    key={category}
                                                    control={
                                                        <Checkbox
                                                            name="pickedProducts"
                                                            checked={pickedProducts.includes(category)}
                                                            value={category}
                                                            onChange={e => {
                                                                if (e.target.checked) arrayHelpers.push(category);
                                                                else {
                                                                    const idx = pickedProducts.indexOf(category);
                                                                    arrayHelpers.remove(idx);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={category}
                                                />
                                            ))}
                                        </>
                                    )}
                                />
                            </FormGroup>

                            <FormHelperText>Can pick multiple products!</FormHelperText>

                        </FormControl>

                        <FormControl component="fieldset" className={classes.formControl}>
                            <FormLabel component="legend">Type of Communication</FormLabel>
                            <FormGroup>
                                <FieldArray
                                    name="pickedRequests"
                                    render={arrayHelpers => (
                                        <>
                                            {requests.map(category => (
                                                <FormControlLabel
                                                    key={category}
                                                    control={
                                                        <Checkbox
                                                            name="pickedRequests"
                                                            checked={pickedRequests.includes(category)}
                                                            value={category}
                                                            onChange={e => {
                                                                if (e.target.checked) arrayHelpers.push(category);
                                                                else {
                                                                    const idx = pickedRequests.indexOf(category);
                                                                    arrayHelpers.remove(idx);
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={category}
                                                />
                                            ))}
                                        </>
                                    )}
                                />
                            </FormGroup>
                            <FormHelperText>Can subscribe multiple types!</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>

                        <FormControlLabel
                            control={<Checkbox
                                name="rgpd_check"
                                value={rgpd_check}
                                checked={rgpd_check}
                                onChange={change.bind(null, "rgpd_check")}
                                color="primary" />}
                            label="By submiting this form, you agree with the storage and use of your data by SAGIPER"
                        />

                        {rgpd_check && (
                            <Fade in={rgpd_check}>
                                <FormControlLabel
                                    control={<Checkbox
                                        name="request_newsletters_check"
                                        value={request_newsletters_check}
                                        checked={request_newsletters_check}
                                        onChange={change.bind(null, "request_newsletters_check")}
                                        color="primary" />}
                                    label="I do accept to receive Marketing communications from SAGIPER in the picked areas"
                                /></Fade>
                        )}

                    </Grid>

                    <Grid item xs={12} style={{ position: 'relative' }}>

                        <Field
                            name="notes"
                            id="notes"
                            label="Notes"
                            value={notes}
                            helperText={touched.notes ? errors.notes : ""}
                            error={touched.notes && Boolean(errors.notes)}
                            fullWidth
                            onChange={change.bind(null, "notes")}
                            component={TextField}
                            multiline
                            rows="4"
                            rowsMax="4"
                        />

                        {attachmentTaken && (
                            <Grid item >
                                <img className={classes.picAttachment} alt="Profile Picture" src={attachmentTaken} />
                            </Grid>
                        )}

                        <Button style={{ position: 'absolute', right: 0, top: '50px' }} variant="outlined" color="default">
                            <AttachFileIcon />
                            <input name="attachment" style={{ position: 'absolute', opacity: 0, width: '100%', height: '100%' }} type="file" onChange={handleFileChange} />
                        </Button>

                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                            onClick={handleReset}
                        >
                            RESET <CachedIcon className={classes.rightIcon} />
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={9}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!isValid}
                            className={classes.submit}>
                            SUBMIT FORM <SendIcon className={classes.rightIcon} />
                        </Button>
                    </Grid>

                </Grid>
            </form>
        </React.Fragment>
    );
};
